import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;
    public doc = Documents;
    public Action = ActionJournaliere;
    public other = Autre;
    public cham = Chambre;
    public com = Commandes;
    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'four',
        path: ['/']
    },
];

export const ActionJournaliere = [
//   {
//     name: 'Réception',
//     iconClasses: 'calendar',
//     path: ['/receptions/reception']
// },
{
  name: 'Réservation',
  iconClasses: 'file',
  path: ['/reservations/reservations']
},
{
  name: 'Invités',
  iconClasses: 'guest',
  path: ['/invités/invites']
},
{
  name: 'Entretien ménager',
  iconClasses: 'housekeeping',
  path: ['/service-chambre/service-chambres']
},
{
  name: 'Tâches',
  iconClasses: 'tools',
  path: ['/taches/tache']
},

]
export const Documents = [
  {
    name: 'Documents',
    iconClasses: 'folder',
    path: ['/documents/documents']
},
{
  name: 'Comptabilité',
  iconClasses: 'accounting',
  path: ['/comptabilite/comptabilite']
},
{
  name: 'Livre de caisse',
  iconClasses: 'euro',
  path: ['/livre-de-caisse/livredecaise']
},
// {
//   name: 'Rapports Quotidiens',
//   iconClasses: 'clipboard',
//   path: ['/rapport-quotidien/rapports-quotidien']
// },

]




export const Autre = [
{
  name: 'Paramétres',
  iconClasses: 'settings',
  children: [
        //   {
        //     name: 'Automatisation',
        //     iconClasses: 'fas fa-circle',
        //     path: ['/parametres/automatisation']
        // },
          // {
          //     name: 'General',
          //     iconClasses: 'fas fa-circle',
          //     path: ['/parametres/general']
          // },
          {
            name: 'Chambres',
            iconClasses: 'fas fa-circle',
            path: ['/parametres/chambres']
        },
      //   {
      //     name: 'Integration',
      //     iconClasses: 'fas fa-circle',
      //     path: ['/parametres/integration']
      // },
      {
        name: 'Employés',
        iconClasses: 'fas fa-circle',
        path: ['/parametres/employees']
    },
    //   {
    //     name: 'Facturation',
    //     iconClasses: 'fas fa-circle',
    //     path: ['/parametres/facturation']
    // },
      {
          name: 'Départements',
          iconClasses: 'fas fa-circle',
          path: ['/parametres/departement']
      },
  //   {
  //     name: 'Offres',
  //     iconClasses: 'fas fa-circle',
  //     path: ['/parametres/offres']
  // },
  ]
},
// {
//   name: 'Aidez-Moi',
//   iconClasses: 'help',
//   path: ['/reservation']
// },


]
export const Chambre = [
  {
    name: 'Moteur de reservation',
    iconClasses: 'booking-engine',
    path: ['/moteur-de-reservations/moteursReservations']
  },
// {
//   name: 'Prix des chambres',
//   iconClasses: 'room-rates',
//   path: ['/prix-chambre/prixChambres']
// },
{
  name: 'Canaux',
  iconClasses: 'channel-manager',
  path: ['/canal/canals']
}
]
export const Commandes = [
//   {
//     name: 'Conciergerie',
//     iconClasses: 'concierge',
//     path: ['/conciergerie/conciergerie']
// },
  {
    name: 'Mes commnades',
    iconClasses: 'ordes',
    path: ['/commande/commandes']
},
{
  name: 'Services',
  iconClasses: 'services',
  path: ['/services/service']
},
{
  name: 'Communications',
  iconClasses: 'conv',
  path: ['/communications/communication']
}
]

