import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        
        children: [
            {
                path: 'profile',
                component: ProfileComponent,


            },
            {
              path: 'canal',
              loadChildren: () => import('@pages/canaux/canaux.module').then(m => m.CanauxModule)
            },
            {
              path: 'commande',
              loadChildren: () => import('@pages/commandes/commandes.module').then(m => m.CommandesModule)
            },
            {
              path: 'conciergerie',
              loadChildren: () => import('@pages/conciergerie/conciergerie.module').then(m => m.ConciergerieModule)
            },
            {
              path: 'communications',
              loadChildren: () => import('@pages/communications/communications.module').then(m => m.CommunicationsModule)
            },
            {
              path: 'comptabilite',
              loadChildren: () => import('@pages/comptabilite/comptabilite.module').then(m => m.ComptabiliteModule)
            },
            {
              path: 'documents',
              loadChildren: () => import('@pages/documents/documents.module').then(m => m.DocumentsModule)
            },
            {
              path: 'invités',
              loadChildren: () => import('@pages/invités/invités.module').then(m => m.InvitésModule)
            },
            {
              path: 'livre-de-caisse',
              loadChildren: () => import('@pages/livre-de-caisse/livre-de-caisse.module').then(m => m.LivreDeCaisseModule)
            },
            {
              path: 'moteur-de-reservations',
              loadChildren: () => import('@pages/moteur-de-reservation/moteur-de-reservation.module').then(m => m.MoteurDeReservationModule)
            },
            {
              path: 'parametres',
              loadChildren: () => import('@pages/parametres/parametres.module').then(m => m.ParametresModule)
            },
            {
              path: 'prix-chambre',
              loadChildren: () => import('@pages/prix-chambre/prix-chambre.module').then(m => m.PrixChambreModule)
            },
            {
              path: 'rapport-quotidien',
              loadChildren: () => import('@pages/rapport-quotidien/rapport-quotidien.module').then(m => m.RapportQuotidienModule)
            },
            {
              path: 'receptions',
              loadChildren: () => import('@pages/receptions/receptions.module').then(m => m.ReceptionsModule)
            },
            {
              path: 'reservations',
              loadChildren: () => import('@pages/reservations/reservations.module').then(m => m.ReservationsModule)
            },
            {
              path: 'service-chambre',
              loadChildren: () => import('@pages/service-chambre/service-chambre.module').then(m => m.ServiceChambreModule)
            },
            {
              path: 'services',
              loadChildren: () => import('@pages/services/services.module').then(m => m.ServicesModule)
            },
            {
              path: 'taches',
              loadChildren: () => import('@pages/taches/taches.module').then(m => m.TachesModule)
            },
            {
                path: '',
                component: DashboardComponent
            }
        ]
    },
    {path:'login', component:LoginComponent},
    {path:'register', component:RegisterComponent},
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
