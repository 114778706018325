import {Component, OnInit} from '@angular/core';
import {Chart, registerables} from '../../../../node_modules/chart.js';
Chart.register(...registerables)
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{

  ngOnInit(): void {
    this.renderChart();
  }
  renderChart(){
    const ctx = new Chart("myChart", {
      type: 'doughnut',
      data: {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [{
          label: '# of Votes',
          data: [12, 19, 3, 5, 2, 3],
          borderWidth: 1
        }]
      },
      options: {
        animation: false,
        plugins: {
          legend: {
            display: false
          } },

      },
    });
  }
}
