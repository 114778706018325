<section class="main">
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-9 d-flex">
                <h1 class="m-0 text-dark">Tableau de bord</h1>
                <ol class="breadcrumb"  style="padding-top:0.5rem">
                  <li class="breadcrumb-item">Accueil</li>
                  <li class="breadcrumb-item active">Tableau de Bord</li>
              </ol>
            </div>
            <!-- /.col -->
            <div class="col-sm-3">
              date
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
            <div class="col-lg-6 col-12">
              <div class="card" style="height: 300px;">
                <div class="card-body">
                  <div class="col-12" >
                    <div class="entete col-12" style="display:flex;justify-content: space-between;">

                    <div style="font-size: 18px;
                    font-weight: bold;
                    line-height: 18px;">Reservations</div>
                    <div class="check"><button class="btn"><i class="fa fa-plus" ></i></button>
                    </div>
                    </div>
                    <canvas  id="myChart"></canvas>

                  </div>
                </div>
              </div>

            </div>
            <div class="col-lg-6 col-12">
              <div class="card" style="height:300px;border: 1px solid rgba(72, 91, 120, 0.05);border-radius: 6px !important;">
                <div class="card-body">
                  <div class="col-12" >
                    <div class="entete col-12" style="display:flex;justify-content: space-between;">

                    <div style="font-size: 18px;
                    font-weight: bold;
                    line-height: 18px;">Gestion des invités</div>
                    <div class="check"><button class="btn"><i class="fa fa-plus" ></i></button>
                    </div>
                    </div>
                    <div class="col-12 d-flex cont">

                    <div class="col-4 ">
                      <p><img class="cio" src="../../../assets/icon/check-in.svg" alt="" srcset=""> Arrivée</p>
                      <div class="list">
                        <div style="display: block;">
                          <img class="cio" src="../../../assets/icon/check-in.svg" alt="" srcset="">
                          <p>Aucun Arrivée</p>
                        </div>

                      </div>
                    </div>
                    <div class="col-4">
                      <p><img class="cio" src="../../../assets/icon/check-out.svg" alt="" srcset=""> Depart</p>
                      <div class="list">
                        <div style="display: block;">
                          <img class="cio" src="../../../assets/icon/check-out.svg" alt="" srcset="">
                          <p>Aucun Départ</p>

                        </div>

                      </div>

                    </div>
                    <div class="col-4">
                      <p><img class="cio" src="../../../assets/icon/in_house.svg" alt="" srcset=""> Logé</p>
                      <div class="list">

                        <div style="display: block;">
                          <img class="cio" src="../../../assets/icon/in_house.svg" alt="" srcset="">
                          <p>Aucun Logé</p>

                        </div>
                      </div>

                    </div>
                  </div>

                  </div>
                </div>
              </div>

            </div>
        </div>
        <!-- /.row -->
    </div>



 <div class="container-fluid">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-lg-4 col-12">
          <div class="card" style="height:290px;border: 1px solid rgba(72, 91, 120, 0.05);border-radius: 6px !important;">
            <div class="card-body">
              <div class="col-12">
                <div class="entete col-12" style="display:flex;justify-content: space-between;">

                <div style="font-size: 18px;
                font-weight: bold;
                line-height: 18px;">Gestion des invités</div>
                <div class="check"><button class="btn"><i class="fa fa-plus" ></i></button>
                </div>
                </div>
                <div class="col-12 cars" style="height: 230px;">
                  <div style="display: block;">
                    <img class="cio" src="../../../assets/icon/no_data.svg" alt="" srcset="">
                    <p>Aucune donnée</p>
                  </div>
                </div>
              </div>
            </div>-
          </div>

        </div>
        <div class="col-lg-4 col-12">
          <div class="card" style="height:290px;border: 1px solid rgba(72, 91, 120, 0.05);border-radius: 6px !important;">
            <div class="card-body">
              <div class="col-12" >
                <div class="entete col-12" style="display:flex;justify-content: space-between;">

                <div style="font-size: 18px;
                font-weight: bold;
                line-height: 18px;">Gestion des invités</div>
                <div class="check"><button class="btn"><i class="fa fa-plus" ></i></button>
                </div>
                </div>
                <div class="col-12 cars" style="height: 230px;">
                  <div style="display: block;">
                    <img class="cio" src="../../../assets/icon/no_data.svg" alt="" srcset="">
                    <p>Aucune donnée</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-4 col-12">
          <div class="card" style="height:290px;border: 1px solid rgba(72, 91, 120, 0.05);border-radius: 6px !important;">
            <div class="card-body">
              <div class="col-12" >
                <div class="entete col-12" style="display:flex;justify-content: space-between;">

                <div style="font-size: 18px;
                font-weight: bold;
                line-height: 18px;">Gestion des invités</div>
                <div class="check"><button class="btn"><i class="fa fa-plus" ></i></button>
                </div>
                </div>
                <div class="col-12 cars" style="height: 230px">
                  <div style="display: block;">
                    <img class="cio" src="../../../assets/icon/no_data.svg" alt="" srcset="">
                    <p>Aucune donnée</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- /.row -->
  </div>


</section>
<!-- /.content -->
</section>
