<!-- Brand Logo -->
<a [routerLink]="['/']"  style="background-color: #283345;text-decoration: none;" class="brand-link">
    <pf-image
        src="assets/img/h.jpg"
        alt="Hotel"
        class="brand-image"
        height="33"
        width="33"
        rounded
        style="opacity: 1"
    ></pf-image>
    <span class="brand-text font-weight-bolder color-white">Hotel New</span>
</a>

<!-- Sidebar -->
<div class="sidebar">


    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <app-menu-item
                *ngFor="let item of menu"
                [menuItem]="item"
            ></app-menu-item>

        </ul>
        <p class="brand-text" style="color: white;font-weight: 400; font-size: 12px; text-transform: uppercase; padding: 15px 0 8px 15px; letter-spacing:1px; line-height: 14px; word-break: break-all; transition:all 0.3s ease;margin-bottom:0px">Opérations Journaliéres</p>
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
        <app-menu-item
        *ngFor="let item of Action"
        [menuItem]="item"
    ></app-menu-item>
    <p class="brand-text" style="color: white;font-weight: 400; font-size: 12px; text-transform: uppercase; padding: 15px 0 8px 15px; letter-spacing:1px; line-height: 14px; word-break: break-all; transition:all 0.3s ease;margin-bottom:0px">Documents</p>
    <app-menu-item
    *ngFor="let item of doc"
    [menuItem]="item"
></app-menu-item>

<p class="brand-text" style="color: white;font-weight: 400; font-size: 12px; text-transform: uppercase; padding: 15px 0 8px 15px; letter-spacing:1px; line-height: 14px; word-break: break-all; transition:all 0.3s ease;margin-bottom:0px">Ventes Externes</p>
<app-menu-item
*ngFor="let item of cham"
[menuItem]="item"
></app-menu-item>

<p class="brand-text" style="color: white;font-weight: 400; font-size: 12px; text-transform: uppercase; padding: 15px 0 8px 15px; letter-spacing:1px; line-height: 14px; word-break: break-all; transition:all 0.3s ease;margin-bottom:0px">En interne</p>
<app-menu-item
*ngFor="let item of com"
[menuItem]="item"
></app-menu-item>

<p class="brand-text" style="color: white;font-weight: 400; font-size: 12px; text-transform: uppercase; padding: 15px 0 8px 15px; letter-spacing:1px; line-height: 14px; word-break: break-all; transition:all 0.3s ease;margin-bottom:0px">Autre</p>
<app-menu-item
*ngFor="let item of other"
[menuItem]="item"
></app-menu-item>
  </ul>
    </nav>



</div>

<a  style="background-color: #283345;padding-top:10px;text-decoration: none;" class="brand-link">
  <pf-image
      src="assets/icon/admin.svg"
      alt="Hotel"
      class="brand-image"
      height="33"
      width="33"
      rounded
      style="opacity: 0.6"
  ></pf-image>
<div style="display:block">
  <p style="font-size: 15px;padding-bottom:0px;margin-bottom: 0px;text-decoration: none;" class="brand-text font-weight-bold color-white">Users</p>
  <p  style="font-size: 15px;padding-bottom:0px;margin-bottom: 0px;text-decoration: none;" class="brand-text color-white">@roles</p>

</div>
</a>
