<section class="h-100 bg-dark">
    <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col">
                <div class="card card-registration my-4">
                    <div class="row g-0">
                        <div class="col-xl-6 d-none d-xl-block">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp" alt="Sample photo" class="img-fluid" style="border-top-left-radius: .25rem; border-bottom-left-radius: .25rem;" />
                        </div>
                        <div class="col-xl-6">
                            <div class="card-body p-md-5 text-black">
                                <h3 class="mb-5 text-uppercase">Registration form</h3>

                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="text" id="form3Example1m" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1m">Premom</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="text" id="form3Example1n" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1n">Nom</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="text" id="form3Example1m1" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1m1">Email</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="text" id="form3Example1n1" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1n1">Country</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-outline">
                                    <input type="phone" id="form3Example1n1" class="form-control form-control-lg" />
                                    <label class="form-label" for="form3Example1n1">Numero de telephone</label>
                                </div>

                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="text" id="form3Example1m1" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1m1">Nom de propriete</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="text" id="form3Example1n1" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1n1">Type de propriete</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="num" id="form3Example1m1" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1m1">Nombre de chambres</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <div class="form-outline">
                                            <input type="url" id="form3Example1n1" class="form-control form-control-lg" />
                                            <label class="form-label" for="form3Example1n1">Lien de la propriete</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-outline">
                                    <textarea class="form-control form-control-lg"></textarea>
                                    <label class="form-label" for="form3Example1n1">Informations supplementaire</label>
                                </div>
                                <div class="d-md-flex justify-content-start align-items-center mb-4 py-2">

                                    <h6 class="mb-0 me-4">Je ne suis pas un robot</h6>

                                    <div class="form-check form-check-inline mb-0 me-4">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="femaleGender" value="option1" />
                                        <label class="form-check-label" for="femaleGender">Female</label>
                                    </div>



                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>